import Link from 'next/link';
import React from 'react'

const FooterLinks = ({ linkData }) => {
  return (
    <div className=''>
      <h3 className=' mb-8'>{linkData.title}</h3>
      {linkData.data.map((link, idx) => (
        <Link href={link.url} key={idx}>
          <div className='my-8 font-bold tracking-[1.4px] cursor-pointer'>{link.title}</div>
        </Link>
      ))}
    </div>
  )
}

export default FooterLinks;
